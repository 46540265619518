import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Grid,
  Paper,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const CreateVendorForm = ({ onClose }) => {
  const [vendorName, setVendorName] = useState("");
  const [vendorDisplayName, setVendorDisplayName] = useState("");
  const [vendorAddress, setVendorAddress] = useState("");
  const [contactName, setContactName] = useState("");
  const [emailAddress, setEmailAddress] = useState("");
  const [workPhone, setWorkPhone] = useState("");
  const [personalPhone, setPersonalPhone] = useState("");
  const [bankName, setBankName] = useState("");
  const [accountNumber, setAccountNumber] = useState("");
  const [ifscCode, setIfscCode] = useState("");
  const [gstNumber, setGstNumber] = useState("");
  const [bankAddress, setBankAddress] = useState("");
  const [otherDetails, setOtherDetails] = useState("");

  const handleSaveDetails = async () => {
    // Check if all fields are filled
    if (
      !vendorName ||
      !vendorDisplayName ||
      !vendorAddress ||
      !contactName ||
      !emailAddress ||
      !workPhone ||
      !personalPhone ||
      !bankName ||
      !accountNumber ||
      !ifscCode ||
      !gstNumber ||
      !bankAddress ||
      !otherDetails
    ) {
      alert("All fields are required. Please fill in all fields.");
      return;
    }

    // Prepare the payload for the API call
    const payload = {
      vendorName,
      vendorDisplayName,
      vendorAddress,
      contactName,
      emailAddress,
      workPhone,
      personalPhone,
      bankName,
      accountNumber,
      ifscCode,
      gstNumber,
      bankAddress,
      otherDetails,
    };

    try {
      // Make the API call to save the vendor details
      const response = await fetch(" https://2tihrfzt93.execute-api.ap-southeast-1.amazonaws.com/Dev/vendor", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      const data = await response.json();

      if (response.ok) {
        alert("Vendor details successfully saved!");
        onClose();
      } else {
        throw new Error(data.message || "Failed to save vendor details.");
      }
    } catch (error) {
      console.error("Error saving vendor data:", error);
      alert("Failed to save vendor details. Please try again later.");
    }
  };

  return (
    <Paper
      elevation={10}
      sx={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        width: "90%",
        maxWidth: "900px",
        padding: 4,
        borderRadius: "16px",
        backgroundColor: "#FAFAFA",
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 12,
          right: 12,
          color: "#6E7E8B",
          "&:hover": {
            color: "#D32F2F",
          },
        }}
      >
        <CloseIcon />
      </IconButton>

      <Typography
        variant="h5"
        sx={{
          fontWeight: "bold",
          color: "#2A3E4C",
          textAlign: "center",
          marginBottom: 3,
          letterSpacing: 1,
        }}
      >
        Create New Vendor
      </Typography>

      <Grid container spacing={2}>
        {[
          { label: "Vendor Name", value: vendorName, setter: setVendorName },
          { label: "Vendor Display Name", value: vendorDisplayName, setter: setVendorDisplayName },
          { label: "Vendor Address", value: vendorAddress, setter: setVendorAddress, multiline: true },
          { label: "Contact Person Name", value: contactName, setter: setContactName },
          { label: "Email Address", value: emailAddress, setter: setEmailAddress },
          { label: "Work Phone Number", value: workPhone, setter: setWorkPhone },
          { label: "Personal Phone Number", value: personalPhone, setter: setPersonalPhone },
          { label: "Bank Name", value: bankName, setter: setBankName },
          { label: "Account Number", value: accountNumber, setter: setAccountNumber },
          { label: "IFSC Code", value: ifscCode, setter: setIfscCode },
          { label: "GST Number", value: gstNumber, setter: setGstNumber },
          { label: "Bank Address", value: bankAddress, setter: setBankAddress, multiline: true },
          { label: "Other Details (Fill NA if Not Applicable)", value: otherDetails, setter: setOtherDetails, multiline: true },
        ].map((field, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <TextField
              label={field.label}
              fullWidth
              variant="outlined"
              value={field.value}
              onChange={(e) => field.setter(e.target.value)}
              required
              multiline={field.multiline || false}
              rows={field.multiline ? 2 : 1}
            />
          </Grid>
        ))}
      </Grid>

      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-end",
          gap: 2,
          marginTop: 4,
        }}
      >
        <Button
          onClick={onClose}
          variant="outlined"
          sx={{
            color: "#2A3663",
            borderColor: "#2A3663",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#F0F4FF",
              borderColor: "#3B4A7A",
            },
          }}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSaveDetails}
          variant="contained"
          sx={{
            backgroundColor: "#2A3663",
            color: "#FFFFFF",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#3B4A7A",
            },
          }}
        >
          Save Details
        </Button>
      </Box>
    </Paper>
  );
};

export default CreateVendorForm;

// import React, { useState } from "react";
// import {
//   Box,
//   TextField,
//   Button,
//   Typography,
//   IconButton,
//   Grid,
// } from "@mui/material";
// import CloseIcon from "@mui/icons-material/Close";
// import AWS from "aws-sdk";

// const CreateVendorForm = ({ onClose }) => {
//   const [vendorName, setVendorName] = useState("");
//   const [vendorDisplayName, setVendorDisplayName] = useState("");
//   const [vendorAddress, setVendorAddress] = useState("");
//   const [contactName, setContactName] = useState("");
//   const [emailAddress, setEmailAddress] = useState("");
//   const [workPhone, setWorkPhone] = useState("");
//   const [personalPhone, setPersonalPhone] = useState("");
//   const [bankName, setBankName] = useState("");
//   const [accountNumber, setAccountNumber] = useState("");
//   const [ifscCode, setIfscCode] = useState("");
//   const [bankAddress, setBankAddress] = useState("");
//   const [gstNumber, setGstNumber] = useState("");
//   const [otherDetails, setOtherDetails] = useState("");

//   // Configure AWS SDK
//   const dynamoDB = new AWS.DynamoDB({
//     region: process.env.REACT_APP_AWS_REGION,
//     accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY_ID,
//     secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
//   });

//   const handleSaveDetails = async () => {
//     // Check if all fields are filled
//     if (
//       !vendorName ||
//       !vendorDisplayName ||
//       !vendorAddress ||
//       !contactName ||
//       !emailAddress ||
//       !workPhone ||
//       !personalPhone ||
//       !bankName ||
//       !accountNumber ||
//       !ifscCode ||
//       !bankAddress ||
//       !gstNumber ||
//       !otherDetails
//     ) {
//       alert("All fields are required. Please fill in all fields.");
//       return;
//     }

//     // Prepare vendor data
//     const timestamp = new Date().toISOString();
//     const itemId = `${vendorName.toLowerCase().replace(/\s+/g, "")}_${Date.now()}`;
//     const vendorData = {
//       item_id: { S: itemId },
//       vendorName: { S: vendorName },
//       vendorDisplayName: { S: vendorDisplayName },
//       vendorAddress: { S: vendorAddress },
//       contactName: { S: contactName },
//       emailAddress: { S: emailAddress },
//       workPhone: { S: workPhone },
//       personalPhone: { S: personalPhone },
//       bankName: { S: bankName },
//       accountNumber: { S: accountNumber },
//       ifscCode: { S: ifscCode },
//       bankAddress: { S: bankAddress },
//       gstNumber: { S: gstNumber },
//       otherDetails: { S: otherDetails },
//       vendorId: { S: itemId },
//       createdAt: { S: timestamp },
//     };

//     try {
//       const params = {
//         TableName: "avenueDataTesting",
//         Item: vendorData,
//       };

//       await dynamoDB.putItem(params).promise();
//       alert("Vendor details successfully saved!");
//       onClose(); // Trigger parent logic to close the form and refresh vendors
//     } catch (error) {
//       console.error("Error saving vendor data:", error);
//       alert("Failed to save vendor details.");
//     }
//   };

//   return (
//     <Box
//       sx={{
//         position: "fixed",
//         top: "50%",
//         left: "50%",
//         transform: "translate(-50%, -50%)",
//         backgroundColor: "#FFFFFF",
//         boxShadow: 3,
//         borderRadius: "12px",
//         width: "90%",
//         maxWidth: "1200px",
//         padding: 3,
//         zIndex: 1000,
//       }}
//     >
//       <IconButton
//         onClick={onClose}
//         sx={{
//           position: "absolute",
//           top: 8,
//           right: 8,
//           color: "#2A3663",
//           "&:hover": {
//             color: "#D32F2F",
//           },
//         }}
//       >
//         <CloseIcon />
//       </IconButton>

//       <Typography
//         variant="h6"
//         sx={{
//           fontWeight: "bold",
//           color: "#2A3663",
//           textAlign: "center",
//           marginBottom: 3,
//         }}
//       >
//         Create New Vendor
//       </Typography>

//       <Grid container spacing={2}>
//         <Grid item xs={6}>
//           <TextField
//             label="Vendor Name"
//             fullWidth
//             value={vendorName}
//             onChange={(e) => setVendorName(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="Vendor Display Name"
//             fullWidth
//             value={vendorDisplayName}
//             onChange={(e) => setVendorDisplayName(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             label="Vendor Address"
//             fullWidth
//             multiline
//             rows={2}
//             value={vendorAddress}
//             onChange={(e) => setVendorAddress(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="Contact Person Name"
//             fullWidth
//             value={contactName}
//             onChange={(e) => setContactName(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="Email Address"
//             fullWidth
//             value={emailAddress}
//             onChange={(e) => setEmailAddress(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="Work Phone Number"
//             fullWidth
//             value={workPhone}
//             onChange={(e) => setWorkPhone(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="Personal Phone Number"
//             fullWidth
//             value={personalPhone}
//             onChange={(e) => setPersonalPhone(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="Bank Name"
//             fullWidth
//             value={bankName}
//             onChange={(e) => setBankName(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="Account Number"
//             fullWidth
//             value={accountNumber}
//             onChange={(e) => setAccountNumber(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="IFSC Code"
//             fullWidth
//             value={ifscCode}
//             onChange={(e) => setIfscCode(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={6}>
//           <TextField
//             label="GST Number"
//             fullWidth
//             value={gstNumber}
//             onChange={(e) => setGstNumber(e.target.value)}
//             required
//           />
//         </Grid>
//         <Grid item xs={12}>
//           <TextField
//             label="Bank Address"
//             fullWidth
//             multiline
//             rows={2}
//             value={bankAddress}
//             onChange={(e) => setBankAddress(e.target.value)}
//             required
//           />
//         </Grid>

//         <Grid item xs={12}>
//           <TextField
//             label="Other Details (Fill NA if Not Applicable)"
//             fullWidth
//             multiline
//             rows={3}
//             value={otherDetails}
//             onChange={(e) => setOtherDetails(e.target.value)}
//             required
//           />
//         </Grid>
//       </Grid>

//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "flex-end",
//           gap: 2,
//           marginTop: 3,
//         }}
//       >
//         <Button
//           onClick={onClose}
//           variant="outlined"
//           sx={{
//             color: "#2A3663",
//             borderColor: "#2A3663",
//             textTransform: "none",
//           }}
//         >
//           Cancel
//         </Button>
//         <Button
//           onClick={handleSaveDetails}
//           variant="contained"
//           sx={{
//             backgroundColor: "#2A3663",
//             color: "#FFFFFF",
//             textTransform: "none",
//             "&:hover": {
//               backgroundColor: "#3B4A7A",
//             },
//           }}
//         >
//           Save Details
//         </Button>
//       </Box>
//     </Box>
//   );
// };

// export default CreateVendorForm;