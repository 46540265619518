import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  Box,
  Typography,
  Paper,
  CircularProgress,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Alert,
} from '@mui/material';

const API_BASE_URL = 'https://wiykuljd3h.execute-api.ap-southeast-1.amazonaws.com/dev';

const warehouses = ['Warehouse 1', 'Warehouse 2', 'Warehouse 3'];
const properties = ['Property A', 'Property B', 'Property C'];

const ItemDetails = () => {
  const { itemName } = useParams(); // Extract itemName from the URL
  const [itemDetails, setItemDetails] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [operation, setOperation] = useState('');
  const [count, setCount] = useState('');
  const [warehouse, setWarehouse] = useState('');
  const [property, setProperty] = useState('');
  const [employeeId, setEmployeeId] = useState('');
  const [operationError, setOperationError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');

  useEffect(() => {
    const fetchItemDetails = async () => {
      if (!itemName) {
        setError('No item name provided.');
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(`${API_BASE_URL}/item?itemName=${encodeURIComponent(itemName)}`);

        if (!response.ok) {
          throw new Error(`Failed to fetch details for item: ${itemName}`);
        }

        const data = await response.json();
        setItemDetails(data);
      } catch (err) {
        console.error('Error fetching item details:', err);
        setError(err.message || 'Unable to fetch item details. Please try again later.');
      } finally {
        setLoading(false);
      }
    };

    fetchItemDetails();
  }, [itemName]);

  const handleSubmit = async () => {
    if (!operation) {
      setOperationError('Please select an operation.');
      return;
    }

    if (!count && (operation === 'add' || operation === 'reduce' || operation === 'return')) {
      setOperationError('Please enter a valid count.');
      return;
    }

    if (!warehouse) {
      setOperationError('Please select a warehouse.');
      return;
    }

    if (!property) {
      setOperationError('Please select a property.');
      return;
    }

    if (!employeeId) {
      setOperationError('Please enter an employee ID.');
      return;
    }

    try {
      const body = {
        item_name: itemName,
        operation,
        count: count ? parseInt(count, 10) : undefined,
        warehouse,
        property,
        employee_id: employeeId,
      };

      const response = await fetch(`${API_BASE_URL}/manage_items`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      });

      if (!response.ok) {
        throw new Error('Failed to perform the operation.');
      }

      const updatedItem = await response.json();
      setItemDetails(updatedItem);
      setCount('');
      setWarehouse('');
      setProperty('');
      setEmployeeId('');
      setOperation('');
      setOperationError('');
      setSuccessMessage('Operation performed successfully!');
    } catch (err) {
      console.error('Error performing operation:', err);
      setOperationError(err.message || 'Failed to perform the operation.');
    }
  };

  if (loading) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <CircularProgress />
        <Typography>Loading item details...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ textAlign: 'center', marginTop: 4 }}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        bgcolor: '#f0f0f0',
        padding: 2,
      }}
    >
      <Paper sx={{ p: 3, width: '100%', maxWidth: 500 }}>
        <Typography variant="h5" gutterBottom sx={{ fontWeight: 'bold' }}>
          Item Details
        </Typography>
        <Typography><strong>Item Name:</strong> {itemDetails.item_name}</Typography>
        <Typography><strong>Location:</strong> {itemDetails.location}</Typography>
        <Typography><strong>Received Quantity:</strong> {itemDetails.received_quantity}</Typography>

        {/* Operation Selection */}
        <FormControl fullWidth variant="outlined" sx={{ mt: 3, mb: 2 }}>
          <InputLabel id="operation-label">Select Operation</InputLabel>
          <Select labelId="operation-label" value={operation} onChange={(e) => setOperation(e.target.value)} label="Select Operation">
            <MenuItem value="add">Add Inventory</MenuItem>
            <MenuItem value="reduce">Issue Inventory</MenuItem>
            <MenuItem value="return">Return Inventory</MenuItem>
            <MenuItem value="move">Move Inventory</MenuItem>
            <MenuItem value="delete">Delete Inventory</MenuItem>
          </Select>
        </FormControl>

        {/* Count Input */}
        <TextField
          fullWidth
          label="Count"
          type="number"
          variant="outlined"
          value={count}
          onChange={(e) => setCount(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* Warehouse Selection */}
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel id="warehouse-label">Select Warehouse</InputLabel>
          <Select labelId="warehouse-label" value={warehouse} onChange={(e) => setWarehouse(e.target.value)} label="Select Warehouse">
            {warehouses.map((wh) => (
              <MenuItem key={wh} value={wh}>{wh}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Property Selection */}
        <FormControl fullWidth variant="outlined" sx={{ mb: 2 }}>
          <InputLabel id="property-label">Select Property</InputLabel>
          <Select labelId="property-label" value={property} onChange={(e) => setProperty(e.target.value)} label="Select Property">
            {properties.map((prop) => (
              <MenuItem key={prop} value={prop}>{prop}</MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* Employee ID Input */}
        <TextField
          fullWidth
          label="Employee ID"
          variant="outlined"
          value={employeeId}
          onChange={(e) => setEmployeeId(e.target.value)}
          sx={{ mb: 2 }}
        />

        {/* Error and Success Messages */}
        {operationError && <Alert severity="error" sx={{ mb: 2 }}>{operationError}</Alert>}
        {successMessage && <Alert severity="success" sx={{ mb: 2 }}>{successMessage}</Alert>}

        {/* Submit Button */}
        <Button variant="contained" color="primary" fullWidth onClick={handleSubmit}>
          Submit Operation
        </Button>
      </Paper>
    </Box>
  );
};

export default ItemDetails;
