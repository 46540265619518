import React, { useState, useEffect } from "react";
import {
  Card,
  CardContent,
  Button,
  Typography,
  Box,
  Grid,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  CircularProgress,
} from "@mui/material";
import axios from "axios";
import { DataGrid } from "@mui/x-data-grid";
import { Worker, Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

const ReadyForReviewForm = ({ invoiceData }) => {
  const data = invoiceData[0];

  const [editableData, setEditableData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [items, setItems] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (data) {
      setEditableData(data);
      setOriginalData(data);

      const updatedItems = (data.products || []).map((item, index) => ({
        id: index + 1,
        code: item.code || "",
        description: item.description || "",
        quantity: item.quantity || "0",
        rate: item.unit_price || "0.00",
        taxable_Value: item.taxable_value || "0.00",
      }));
      setItems(updatedItems);
    }
  }, [data]);

  // Track field changes
  const handleInputChange = (field, value) => {
    setEditableData({ ...editableData, [field]: value });
  };

  // Check if any fields are edited
  const isDataEdited = () => {
    return JSON.stringify(editableData) !== JSON.stringify(originalData);
  };

  const handleStatusChange = async (status) => {
    setLoading(true);
  
    try {
      const payload = {
        avenue_created_invoice_id: editableData.avenue_created_invoice_id,
        bill_status: status,
        updated_fields: getEditedFields(), // Can be empty
      };
  
      console.log("Final payload being sent:", payload);
  
      const response = await axios.post(
        "https://z8knuzragg.execute-api.ap-southeast-1.amazonaws.com/dev/itemid",
        payload,
        { headers: { "Content-Type": "application/json" } }
      );
  
      if (response.status === 200) {
        showDialog(`Status updated to '${status}' successfully!`, "success");
        setOriginalData(editableData);
      } else {
        showDialog(`Failed to update status. Status code: ${response.status}`, "error");
      }
    } catch (error) {
      console.error("Error updating status:", error);
      showDialog(`Failed to update status: ${error.message}`, "error");
    } finally {
      setLoading(false);
    }
  };
  
  const getOriginalFields = () => {
    const fields = {};
    Object.keys(originalData).forEach((key) => {
      if (typeof originalData[key] === "string") {
        fields[key] = originalData[key];
      }
    });
    return fields;
  };
  

  // Get only the edited fields or return original data if no changes
  const getEditedFields = () => {
    const editedFields = {};
    Object.keys(editableData).forEach((key) => {
      if (editableData[key] !== originalData[key]) {
        editedFields[key] = editableData[key];
      }
    });
    return editedFields;
  };
  

  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  // DataGrid columns for the items table
  const columns = [
    { field: "description", headerName: "Description", flex: 2, editable: true },
    { field: "code", headerName: "HSN Code", flex: 1, editable: true },
    { field: "quantity", headerName: "Quantity", flex: 1, editable: true },
    { field: "rate", headerName: "Rate", flex: 1, editable: true },
    { field: "taxable_Value", headerName: "Taxable Value", flex: 1, editable: true },
    
  ];

  // Calculate totals for summary
  const calculateSubtotal = () =>
    items.reduce((sum, item) => sum + parseFloat(item.taxable_Value || 0), 0).toFixed(2);

  const calculateTotalTax = () =>
    (parseFloat(calculateSubtotal()) * 0.18).toFixed(2); // Assuming 18% tax rate

  const calculateGrandTotal = () =>
    ((calculateSubtotal()) + (calculateTotalTax()));

  return (
    <Card sx={{ width: "98%", borderRadius: 2, padding: 2, backgroundColor: "#F9F9F9", boxShadow: 3 }}>
      <Typography
        sx={{
          height: 72,
          textAlign: "center",
          fontSize: "22px",
          fontWeight: "bold",
          color: "#FFFFFF",
          backgroundColor: "#2A3663",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          px: 3,
        }}
      >
        Invoice Details #{editableData.json_invoice_number || "N/A"}
        <Box>
          <Button variant="contained" color="success" sx={{ mr: 2 }} onClick={() => handleStatusChange("REVIEWED")}>
            Reviewed
          </Button>
          <Button variant="contained" color="error" onClick={() => handleStatusChange("REJECTED")}>
            Reject
          </Button>
        </Box>
      </Typography>

      <CardContent>
        {loading && <CircularProgress sx={{ display: "block", margin: "0 auto 16px" }} />}
        <Box sx={{ display: "flex", gap: 2 }}>
          {/* Left Side - PDF Viewer */}
          <Box sx={{ width: "50%", height: "100%" }}>
            <Paper sx={{ padding: 2, height: "100%", overflowY: "auto" }}>
              <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
                {data?.file_url ? <Viewer fileUrl={data.file_url} /> : <Typography>No PDF Available</Typography>}
              </Worker>
            </Paper>
          </Box>

          {/* Right Side - Form Fields and Items Table */}
          <Box sx={{ width: "50%", display: "flex", flexDirection: "column", gap: 2 }}>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Invoice Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField label="Invoice Number" value={editableData.json_invoice_number || ""} fullWidth disabled />
                </Grid>
                <Grid item xs={6}>
                  <TextField label="Invoice Date" value={editableData.invoice_date || ""} fullWidth disabled />
                </Grid>
              </Grid>
            </Paper>
            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Vendor Bank Details
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    label="Bank Name"
                    value={editableData.bank_name || ""}
                    onChange={(e) => handleInputChange("bank_name", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Bank Account Number"
                    value={editableData.bank_account_no || ""}
                    onChange={(e) => handleInputChange("bank_account_no", e.target.value)}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label="Bank IFSC"
                    value={editableData.bank_ifsc || ""}
                    onChange={(e) => handleInputChange("bank_ifsc", e.target.value)}
                    fullWidth
                  />
                </Grid>
              </Grid>
            </Paper>

            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Item Details
              </Typography>
              <DataGrid rows={items} columns={columns} autoHeight />
            </Paper>

            <Paper sx={{ padding: 2 }}>
              <Typography variant="h6" sx={{ mb: 2 }}>
                Invoice Summary
              </Typography>
              <Typography><strong>Subtotal:</strong> ₹{calculateSubtotal()}</Typography>
              <Typography><strong>Total Tax (18%):</strong> ₹{calculateTotalTax()}</Typography>
              <Typography variant="h6"><strong>Grand Total:</strong> ₹{calculateGrandTotal()}</Typography>
            </Paper>
          </Box>
        </Box>
      </CardContent>

      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{dialogSeverity === "success" ? "Success" : "Error"}</DialogTitle>
        <DialogContent>
          <Typography>{dialogMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default ReadyForReviewForm;
