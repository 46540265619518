import React from 'react';
import { Box, Typography } from '@mui/material';
import BillsList from './BillsList';
// import InvoicePdf from './InvoicePdf';

import InventoryList from './InventoryList';
export default function FormTypeRenderer({ formType }) {
  return (
    <Box sx={{ padding: 0 }}>
      {formType === 'Create New Land Deal' && (
        <Typography variant="h4">Create New Land Deal Form Coming Soon...</Typography>
      )}
      {formType === 'View Land Deals' && (
        <Typography variant="h4">View Land Deals Coming Soon...</Typography>
      )}
      {formType === 'Inventory Management' && (
        <Typography variant="h4">Inventory Management Coming Soon...</Typography>
      )}
      {formType === 'View Bills' && <BillsList />}
      {/* {formType === 'HiTl' && <InvoicePdf />} */}
      {formType === 'View Inventory' && <InventoryList />}
      {!formType && (
        <BillsList />
      )}
      
    </Box>
  );
}
