import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { useNavigate } from "react-router-dom";
import AppBarWithSearch from "./AppBarWithSearch";
function LoginPage({ onLoginSuccess }) {
  const navigate = useNavigate();

  const logEventToDynamoDB = async (data) => {
    try {
      // await fetch("http://localhost:8000/log", {
      await fetch("https://test.datso.io/log", {

        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      });
    } catch (error) {
      console.error("Error logging event to DynamoDB:", error);
    }
  };

  const handleLogin = async (response) => {
    if (response && response.credential) {
      try {
        const decoded = JSON.parse(atob(response.credential.split(".")[1]));
        const userEmail = decoded.email;

        // Log the login attempt to the backend
        const roleResponse = await fetch(
          // `http://localhost:8000/roles?email=${userEmail}`
          `https://test.datso.io/roles?email=${userEmail}`
        );
        if (!roleResponse.ok) {
          throw new Error("Failed to fetch role from backend");
        }

        const roleData = await roleResponse.json();
        const userRole = roleData.role;

        if (userRole) {
          // Log the login event
          const loginData = {
            email: userEmail,
            action: "login",
            page: "/",
          };
          await logEventToDynamoDB(loginData);

          // Callback to update the parent component state
          onLoginSuccess(userEmail);

          // Redirect user based on their role
          if (userRole === "admin") {
            navigate("/home");
          } else {
            navigate("/home");
          }
        } else {
          // If no role is found, navigate to the coming soon page
          navigate("/comingsoon");
        }
      } catch (error) {
        console.error("Login failed: ", error);
      }
    } else {
      console.error("Invalid response from Google Login.");
    }
  };

  return (
    
    <div style={styles.container}>
       
      <div style={styles.loginBox}>
        <h2 style={styles.loginText}>Login via Google</h2>
        <GoogleLogin
          onSuccess={handleLogin}
          onError={() => console.log("Login failed")}
        />
      </div>
    </div>
  );
}

const styles = {
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100vh",
    backgroundColor: "#f5f5f5",
  },
  loginBox: {
    textAlign: "center",
    backgroundColor: "#ffffff",
    padding: "2rem",
    borderRadius: "8px",
    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
  },
  loginText: {
    color: "#6200ea",
    textAlign: "center",
    marginBottom: "1rem",
  },
};
  
export default LoginPage;