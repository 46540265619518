import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";

const ReadyForPayment = ({ invoiceData = [], onBack, onReject }) => {
  // Extract the first item from the array
  const data = invoiceData[0] || {};

  const {
    supplier_name,
    supplier_address,
    invoice_date,
    invoice_number,
    json_invoice_number,
    products = [],
    other_deductions = 0,
    taxes_igst = 0,
    total = 0,
    file_url,
  } = data;

  // PDF Dialog state
  const [openPdfDialog, setOpenPdfDialog] = useState(false);

  // Open and close PDF dialog
  const handlePdfDialogOpen = () => {
    setOpenPdfDialog(true);
  };

  const handlePdfDialogClose = () => {
    setOpenPdfDialog(false);
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: "#F9F9F9", height: "90%" }}>
      {/* Header */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "black" }}>
          Invoice Overview #{json_invoice_number || "NA"}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <IconButton onClick={handlePdfDialogOpen} color="primary">
            <VisibilityIcon />
          </IconButton>
          <Button
            sx={{ textTransform: "none", backgroundColor: "#2A3663", fontWeight: "bold" }}
            variant="contained"
            color="primary"
            onClick={onBack}
          >
            Make Payment
          </Button>
          <Button
            sx={{ textTransform: "none", backgroundColor: "#FF0000", fontWeight: "bold" }}
            variant="contained"
            color="error"
            onClick={onReject}
          >
            Reject
          </Button>
        </Box>
      </Box>

      {/* Invoice Overview */}
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
          Vendor Name: {supplier_name || "Vendor Name"}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          Vendor Address: {supplier_address || "NA"}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          Invoice Date: {invoice_date || "NA"}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          Invoice Number: {invoice_number || "NA"}
        </Typography>
      </Box>

      {/* Item Table */}
      <TableContainer component={Paper} sx={{ marginBottom: "20px" }}>
        <Table>
          <TableHead sx={{ backgroundColor: "#2A3663" }}>
            <TableRow>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Item Details</TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Quantity</TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Rate Per Unit</TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((item, index) => (
              <TableRow key={index}>
                <TableCell>{item.description || "NA"}</TableCell>
                <TableCell>{item.quantity || 0}</TableCell>
                <TableCell>₹{item.unit_price || 0}</TableCell>
                <TableCell>₹{item.taxable_value || 0}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Totals */}
      <Box sx={{ textAlign: "right", padding: 3 }}>
        <Typography variant="body1" sx={{ color: "#555" }}>
          Other Deductions: ₹{other_deductions.toLocaleString()}
        </Typography>
        <Typography variant="body1" sx={{ color: "#555" }}>
          Total Tax: ₹{taxes_igst.toLocaleString()}
        </Typography>
        <Divider sx={{ my: 1 }} />
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2A3663" }}>
          Total Price: ₹{total.toLocaleString()}
        </Typography>
      </Box>

      {/* PDF Dialog */}
      <Dialog open={openPdfDialog} onClose={handlePdfDialogClose} fullWidth maxWidth="lg">
        <DialogTitle>Invoice PDF</DialogTitle>
        <DialogContent>
          {invoiceData.file_url ? (
            <object
              data={invoiceData.file_url}
              type="application/pdf"
              width="100%"
              height="500px"
            >
              <p>No PDF Available</p>
            </object>
          ) : (
            <Typography>No PDF Available</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handlePdfDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ReadyForPayment;
