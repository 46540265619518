import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const CreateInventoryModal = ({ materialDetails, onClose, invoiceData }) => {
  const [selectedItems, setSelectedItems] = useState(
    materialDetails.map((item) => ({
      ...item,
      isSelected: true,
      receivedQuantity: item.quantity,
      location: "",
      warehouse: "",
    }))
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success");

  const handleCheckboxChange = (index) => {
    setSelectedItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };

  const handleFieldChange = (index, field, value) => {
    setSelectedItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, [field]: value } : item
      )
    );
  };

  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity);
    setOpenDialog(true);
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    onClose();
  };

  const handleSubmit = async () => {
    const itemsToCreate = selectedItems.filter((item) => item.isSelected);
  
    if (itemsToCreate.length === 0) {
      showDialog("No items selected.", "error");
      return;
    }
  
    // Ensure `invoiceData` is an object, not an array
    const correctedInvoiceData = Array.isArray(invoiceData) ? invoiceData[0] : invoiceData;
    console.log(invoiceData)
  
    const payload = {
      items: itemsToCreate.map((item) => ({
        item_name: item.product_description,
        receivedQuantity: Number(item.receivedQuantity),
        location: item.location,
        warehouse: item.warehouse,
        rate: item.rate,
      })),
      invoiceData: {
        json_invoice_number: correctedInvoiceData.json_invoice_number,
        supplier_gstin: correctedInvoiceData.supplier_gstin,
      invoice_date: correctedInvoiceData.invoice_date,
      recipient_name: correctedInvoiceData.recipient_name,
      }
    };
  
    console.log("Payload being sent:", JSON.stringify(payload, null, 2));
  
    try {
      const response = await fetch(
        "https://wiykuljd3h.execute-api.ap-southeast-1.amazonaws.com/dev/warehouse",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      const result = await response.json();
  
      if (response.ok) {
        showDialog("Items created successfully!", "success");
      } else {
        showDialog(`Error: ${result.message}`, "error");
      }
    } catch (error) {
      console.error("Error creating inventory:", error);
      showDialog("An error occurred while creating inventory.", "error");
    }
  };
  
  

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "20px", fontWeight: "bold" }}>
        Create Inventory
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Item Details</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Received Quantity</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Warehouse</TableCell>
              <TableCell>Rate Per Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={item.isSelected}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </TableCell>
                <TableCell>{item.product_description}</TableCell>
                <TableCell>{item.quantity}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    size="small"
                    value={item.receivedQuantity}
                    onChange={(e) => handleFieldChange(index, "receivedQuantity", e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={item.location}
                    onChange={(e) => handleFieldChange(index, "location", e.target.value)}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    value={item.warehouse}
                    onChange={(e) => handleFieldChange(index, "warehouse", e.target.value)}
                  />
                </TableCell>
                <TableCell>{item.rate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <Button onClick={onClose} sx={{ marginRight: "10px" }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Create Inventory
        </Button>
      </Box>

      {/* Dialog to show alert message */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{dialogSeverity === "success" ? "Success" : "Error"}</DialogTitle>
        <DialogContent>{dialogMessage}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default CreateInventoryModal;
