import React, { useState } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Checkbox,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

const AddInventoryModal = ({ materialDetails, onClose }) => {
  const [selectedItems, setSelectedItems] = useState(
    materialDetails.map((item) => ({
      ...item,
      isSelected: true, // By default, all items are selected
      receivedQuantity: item.quantity, // Default to full quantity
    }))
  );

  // Dialog State
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success"); // success | error

  // Handle checkbox selection
  const handleCheckboxChange = (index) => {
    setSelectedItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, isSelected: !item.isSelected } : item
      )
    );
  };

  // Handle quantity change
  const handleQuantityChange = (index, value) => {
    setSelectedItems((prev) =>
      prev.map((item, i) =>
        i === index ? { ...item, receivedQuantity: value } : item
      )
    );
  };

  // Show dialog with message and severity (success or error)
  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity);
    setOpenDialog(true);
  };

  // Close the dialog
  const handleDialogClose = () => {
    setOpenDialog(false);
    onClose(); // Close the modal after dialog
  };

  // Submit selected items
  const handleSubmit = async () => {
    const itemsToAdd = selectedItems.filter((item) => item.isSelected);
  
    if (itemsToAdd.length === 0) {
      showDialog("No items selected for updating.", "error");
      return;
    }
  
    const payload = {
      items: itemsToAdd.map((item) => ({
        item_name: item.product_description,
        count_change: Number(item.receivedQuantity),
      })),
    };
  
    console.log("Request Payload:", payload);
  
    try {
      const response = await fetch(
        "https://wiykuljd3h.execute-api.ap-southeast-1.amazonaws.com/dev/warehouse",
        {
          method: "PATCH",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );
  
      if (!response.ok) {
        const result = await response.json();
        showDialog(`Failed to update items. Error: ${result.message}`, "error");
        return;
      }
  
      showDialog("Successfully updated selected items.", "success");
    } catch (error) {
      console.error("Error updating inventory:", error);
      showDialog("Failed to update inventory. Please try again.", "error");
    }
  };
  

  return (
    <Box sx={{ padding: "20px" }}>
      <Typography variant="h6" sx={{ marginBottom: "20px", fontWeight: "bold" }}>
        Add to Inventory
      </Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>Item Details</TableCell>
              {/* <TableCell>Account</TableCell> */}
              <TableCell>Quantity</TableCell>
              <TableCell>Received Quantity</TableCell>
              <TableCell>Rate Per Unit</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {selectedItems.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={item.isSelected}
                    onChange={() => handleCheckboxChange(index)}
                  />
                </TableCell>
                <TableCell>{item.product_description}</TableCell>
                {/* <TableCell>{item.account}</TableCell> */}
                <TableCell>{item.quantity}</TableCell>
                <TableCell>
                  <TextField
                    type="number"
                    size="small"
                    value={item.receivedQuantity}
                    onChange={(e) =>
                      handleQuantityChange(index, e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>{item.rate}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box sx={{ display: "flex", justifyContent: "flex-end", marginTop: "20px" }}>
        <Button onClick={onClose} sx={{ marginRight: "10px" }}>
          Cancel
        </Button>
        <Button variant="contained" onClick={handleSubmit}>
          Add Selected Items
        </Button>
      </Box>

      {/* Dialog to show alert message */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{dialogSeverity === "success" ? "Success" : "Error"}</DialogTitle>
        <DialogContent>{dialogMessage}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default AddInventoryModal;
