import React, { useState, useEffect } from "react";
import { GoogleOAuthProvider } from "@react-oauth/google";
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import BillsList from "./components/BillsList";
import UploadInvoice from "./components/UploadInvoice";
import MainPage from "./components/MainApp"
import ComingSoonPage from "./components/ComingSoonPage";
import LoginPage from "./components/LoginPage";
import BillDetails from "./components/BillDetails";
import PaidBillForm from "./components/PaidBillForm";
import { Details } from "@mui/icons-material";
import ItemDetails from "./components/ItemDetails"

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [email, setEmail] = useState(null);
  const [role, setRole] = useState(null);
  const [timeoutId, setTimeoutId] = useState(null);

  useEffect(() => {
    const storedIsLoggedIn = localStorage.getItem("isLoggedIn") === "true";
    const storedEmail = localStorage.getItem("email");
    const storedRole = localStorage.getItem("role");

    if (storedIsLoggedIn && storedEmail && storedRole) {
      setIsLoggedIn(true);
      setEmail(storedEmail);
      setRole(storedRole);
    }
  }, []);

  const handleLoginSuccess = async (email, navigate) => {
    try {
      // const response = await fetch(`http://localhost:8000/roles?email=${email}` );
        const response = await fetch(`https://test.datso.io/roles?email=${email}`);

      if (!response.ok) throw new Error(`Error: ${response.statusText}`);
      const data = await response.json();

      if (data.role) {
        setIsLoggedIn(true);
        setEmail(email);
        setRole(data.role);

        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("email", email);
        localStorage.setItem("role", data.role);

        logEvent("login");
        startInactivityTimeout();
        navigate("/testhome");
      } else {
        navigate("/comingsoon");
      }
    } catch (error) {
      console.error("Login failed: ", error);
    }
  };

  const handleLogout = (navigate) => {
    logEvent("logout");
    setIsLoggedIn(false);
    setEmail(null);
    setRole(null);
    clearTimeout(timeoutId);

    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("email");
    localStorage.removeItem("role");
    navigate("/");
  };

  const logEvent = (action) => {
    // fetch("http://localhost:8000/log", {
      fetch("https://test.datso.io/log", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        email,
        action,
        page: window.location.pathname,
      }),
    });
  };

  const startInactivityTimeout = () => {
    clearTimeout(timeoutId);
    const id = setTimeout(() => {
      alert("Logged out due to inactivity.");
      setIsLoggedIn(false);
    }, 6000000); // 15 minutes
    setTimeoutId(id);
  };

  useEffect(() => {
    const resetTimeout = () => startInactivityTimeout();
    if (isLoggedIn) {
      window.addEventListener("click", resetTimeout);
      window.addEventListener("keypress", resetTimeout);
    }
    return () => {
      window.removeEventListener("click", resetTimeout);
      window.removeEventListener("keypress", resetTimeout);
      clearTimeout(timeoutId);
    };
  }, [isLoggedIn]);

  return (
    <GoogleOAuthProvider clientId="956925433530-3rkehhq6gir119e5735tfci7tlouh6un.apps.googleusercontent.com">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={
              isLoggedIn ? (
                <Navigate to="/home" />
              ) : (
                <LoginPage onLoginSuccess={handleLoginSuccess} />
              )
            }
          />
          <Route
            path="/home"
            element={
              isLoggedIn ? (
                <MainPage
                  role={role}
                  userEmail={email}
                  onLogout={(navigate) => handleLogout(navigate)}
                />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/bills"
            element={
              isLoggedIn ? (
                <BillsList role={role} userEmail={email} />
              ) : (
                <Navigate to="/" />
              )
            }
          />
          <Route
            path="/upload"
            element={
              <BillsList role={role} userEmail={email} />

            }
          />
          <Route path="/comingsoon" element={< ComingSoonPage/>} />
          <Route path="/details/:itemName" element={< ItemDetails/>} />
        </Routes>
      </BrowserRouter>
    </GoogleOAuthProvider>
  );
}

export default App;