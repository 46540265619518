import React, { useState } from 'react';
import { Box } from '@mui/material'; // Import Box for styling
import AppBarWithSearch from './AppBarWithSearch';
import FormTypeRenderer from './FormTypeRenderer';

export default function MainApp() {
  const [formType, setFormType] = useState('');

  return (
    <Box
      sx={{
        minHeight: '100vh', // Full height of the viewport
        background: 'linear-gradient(270deg, #2A3663 0%, #B4D6FF 100%)', // Gradient background
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {/* AppBar */}
      <AppBarWithSearch formType={formType} setFormType={setFormType} />

      {/* Content Renderer */}
      <Box
        sx={{
          flex: 1, // Ensures the content takes up the remaining height
          marginTop: 4,
          marginRight: 2,
          marginLeft: 0,
          padding: 1, // Add padding for content spacing
          
          color: '#FFFFFF', // Default text color to white for readability
        }}
      >
        <FormTypeRenderer formType={formType} />
      </Box>
    </Box>
  );
}
