import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  IconButton,
  Card,
  CardContent,
  Grid,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import VendorDropdown from "./VendorDropdown";
import CreateNewVendor from "./CreateVendorForm";

const UploadInvoice = ({ onClose }) => {
  const [invoiceTitle, setInvoiceTitle] = useState("");
  const [invoiceNumber, setInvoiceNumber] = useState("");
  const [invoiceDate, setInvoiceDate] = useState("");
  const [taxInfo, setTaxInfo] = useState("");
  const [selectedVendor, setSelectedVendor] = useState(null);
  const [file, setFile] = useState(null);
  const [remarks, setRemarks] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [showCreateVendor, setShowCreateVendor] = useState(false); // Toggle for CreateNewVendor

  // Dialog state for alerts
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogMessage, setDialogMessage] = useState("");
  const [dialogSeverity, setDialogSeverity] = useState("success"); // success | error

  const handleFileUpload = (event) => {
    setFile(event.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!invoiceNumber || !selectedVendor || !file) {
      showDialog("Please fill in all required fields.", "error");
      return;
    }
    setIsDisabled(true); // Disable the button and show "Processing..."
    try {
      const metadata = JSON.stringify({
        invoice_title: invoiceTitle,
        invoice_number: invoiceNumber,
        invoice_date: invoiceDate,
        tax_info: taxInfo,
        vendor: selectedVendor,
        remarks: remarks,
      });
      const formData = new FormData();
      formData.append("file", file);
      formData.append("metadata", metadata);
      const response = await fetch("https://test.datso.io/upload", {
        method: "POST",
        body: formData,
      });
      if (response.ok) {
        showDialog('Data has been sent to "REVIEW". The backend team can access it now.', "success");
        // Reset all fields
        setInvoiceNumber("");
        setSelectedVendor(null);
        setFile(null);
        setRemarks("");
        // onClose(); // Close the PIP window (if needed)
      } else {
        const error = await response.json();
        console.error("Error during submission:", error);
        showDialog("Failed to submit. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error during submission:", error);
      showDialog("Failed to submit. Please try again.", "error");
    } finally {
      setIsDisabled(false); // Re-enable the button
    }
  };

  // Function to show dialog
  const showDialog = (message, severity) => {
    setDialogMessage(message);
    setDialogSeverity(severity);
    setOpenDialog(true);
  };

  // Close dialog handler
  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  return (
    <Card
      sx={{
        boxShadow: 3,
        borderRadius: "16px",
        overflow: "hidden",
        backgroundColor: "#F9FAFC",
        maxWidth: "800px",
        margin: "auto",
        position: "relative",
      }}
    >
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          top: 16,
          right: 16,
          color: "#555",
          "&:hover": { color: "#D32F2F" },
        }}
      >
        <CloseIcon />
      </IconButton>
      <CardContent>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
            color: "#2A3663",
            textAlign: "center",
            marginBottom: 3,
          }}
        >
          Upload Invoice
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                label="Invoice Number"
                value={invoiceNumber}
                onChange={(e) => setInvoiceNumber(e.target.value)}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <VendorDropdown
                onSelect={setSelectedVendor}
                value={selectedVendor}
                onCreateVendor={() => setShowCreateVendor(true)}
              />
            </Grid>

            <Grid item xs={12}>
              <Typography>Upload Invoice File</Typography>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  border: "2px dashed #3f51b5",
                  borderRadius: "8px",
                  padding: 2,
                  cursor: "pointer",
                }}
              >
                <Button
                  variant="text"
                  component="label"
                  sx={{ color: "#3f51b5" }}
                >
                  Drag & Drop File or Click to Upload
                  <input
                    type="file"
                    accept="application/pdf,image/jpeg,image/jpg"
                    hidden
                    onChange={handleFileUpload}
                  />
                </Button>
              </Box>
              {file && <Typography>Uploaded File: {file.name}</Typography>}
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="Remarks"
                value={remarks}
                onChange={(e) => setRemarks(e.target.value)}
                multiline
                rows={3}
                fullWidth
                variant="outlined"
              />
            </Grid>

            <Grid item xs={12}>
              <Button
                type="submit"
                variant="contained"
                fullWidth
                disabled={isDisabled}
                sx={{
                  backgroundColor: isDisabled ? "#ccc" : "#3f51b5",
                }}
              >
                {isDisabled ? "Processing..." : "Submit"}
              </Button>
            </Grid>
          </Grid>
        </form>
        {showCreateVendor && <CreateNewVendor onClose={() => setShowCreateVendor(false)} />}
      </CardContent>

      {/* Dialog for success/error alerts */}
      <Dialog open={openDialog} onClose={handleDialogClose}>
        <DialogTitle>{dialogSeverity === "success" ? "Success" : "Error"}</DialogTitle>
        <DialogContent>{dialogMessage}</DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default UploadInvoice;
