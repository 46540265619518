import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Dialog,
  IconButton,
  CircularProgress,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddInventoryModal from "./AddInventoryModal";
import CreateInventoryModal from "./CreateInventoryModal";
import axios from "axios";

const ReviewedForm = ({ invoiceData = [] }) => {
  const data = invoiceData[0] || {};
  const {
    avenue_created_invoice_id = "NA",
    supplier_name = "NA",
    supplier_address = "NA",
    supplier_gstin = "NA",
    invoice_date = "NA",
    invoice_number = "NA",
    products = [],
  } = data;

  const [apiProducts, setApiProducts] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [isAddInventoryOpen, setIsAddInventoryOpen] = useState(false);
  const [isCreateInventoryOpen, setIsCreateInventoryOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const apiEndpoint = `https://z8knuzragg.execute-api.ap-southeast-1.amazonaws.com/dev/fetchitems`;

  useEffect(() => {
    if (avenue_created_invoice_id !== "NA") {
      fetchApiProducts();
    }
  }, [avenue_created_invoice_id]);

  const fetchApiProducts = async () => {
    try {
      setLoading(true);
      const response = await axios.get(apiEndpoint, {
        params: {
          avenue_created_invoice_id,
        },
      });
      console.log("API response:", response.data);
      setApiProducts(response.data);
    } catch (err) {
      console.error("Error fetching products:", err);
      setError("Error fetching products. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleClick = (e, index) => {
    const isChecked = e.target.checked;
    const product = apiProducts[index];

    if (isChecked) {
      setSelectedRows((prev) => [...prev, product]);
    } else {
      setSelectedRows((prev) => prev.filter((item) => item !== product));
    }
  };

  const handleAddInventory = () => setIsAddInventoryOpen(true);
  const handleCloseAddInventory = () => setIsAddInventoryOpen(false);

  const handleCreateInventory = () => setIsCreateInventoryOpen(true);
  const handleCloseCreateInventory = () => setIsCreateInventoryOpen(false);

  return (
    <Box sx={{ padding: 4, backgroundColor: "#F9F9F9", minHeight: "100vh" }}>
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2A3663" }}>
          Invoice Overview #{avenue_created_invoice_id}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <IconButton color="primary">
            <VisibilityIcon />
          </IconButton>
          <Button variant="contained" onClick={handleAddInventory}>
            Add Inventory
          </Button>
          <Button variant="contained" onClick={handleCreateInventory}>
            Create Inventory
          </Button>
        </Box>
      </Box>
      {/* <Box sx={{ backgroundColor: "#FFFFFF", borderRadius: "8px", padding: "20px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "20px" }}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>Vendor Name: {supplier_name}</Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>Vendor Address: {supplier_address}</Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>GSTIN: {supplier_gstin}</Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>Date: {invoice_date}</Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>Invoice Number: {invoice_number}</Typography>
      </Box> */}
      <Box
          sx={{
           backgroundColor: "#FFFFFF", borderRadius: "8px", padding: "20px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", marginBottom: "20px", display: "flex",
           justifyContent: "space-between",
           alignItems: "center"
          }}
        >
          <Box>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
              Vendor Name: {supplier_name || "Vendor Name"}
            </Typography>
            <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
              Vendor Address: {supplier_address}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              GSTIN: {supplier_gstin || "NA"}
            </Typography>
          </Box>
          <Box textAlign="right">
            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              Date: {invoice_date || "NA"}
            </Typography>
            <Typography variant="subtitle1" sx={{ color: "#555" }}>
              Invoice Number: {invoice_number || "NA"}
            </Typography>
          </Box>
        </Box>
     

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <TableContainer component={Paper} sx={{ marginBottom: "20px", borderRadius: "8px" }}>
          <Table>
            <TableHead sx={{ backgroundColor: "#2A3663" }}>
              <TableRow>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Item Description</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Code</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Quantity</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Unit Price</TableCell>
                <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Total Price</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {apiProducts.map((product, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <Checkbox
                      checked={selectedRows.includes(product)}
                      onChange={(e) => handleClick(e, index)}
                    />
                    {product.product_description || "NA"}
                  </TableCell>
                  <TableCell>{product.hsn_code || "NA"}</TableCell>
                  <TableCell>{product.quantity || 0}</TableCell>
                  <TableCell>₹{parseFloat(product.unit_price || 0).toFixed(2)}</TableCell>
                  <TableCell>₹{(product.total_value || 0)}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

<Dialog open={isAddInventoryOpen} onClose={handleCloseAddInventory} maxWidth="md">
        <AddInventoryModal materialDetails={selectedRows} invoiceData={invoiceData} onClose={handleCloseAddInventory} />
      </Dialog>

      {/* Create Inventory Modal */}
      <Dialog open={isCreateInventoryOpen} onClose={handleCloseCreateInventory} maxWidth="md" fullWidth>
        <CreateInventoryModal materialDetails={selectedRows} invoiceData={invoiceData} onClose={handleCloseCreateInventory} />
      </Dialog>
    </Box>
  );
};

export default ReviewedForm;
