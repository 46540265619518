import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  IconButton,
  TablePagination,
} from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import UploadInvoice from "./UploadInvoice";
import ReadyForReview from "./ReadyForReviewForm";
import ReadyForPayment from "./ReadyForPaymentForm";
import PaidBillForm from "./PaidBillForm";
import ReviewedForm from "./ReviewedForm";

const statusColors = {
  "READY_FOR_REVIEW": " #ff944d",
  "READY FOR REVIEW": " #ff944d",
  "REVIEWED": " #1a75ff",
  "READY FOR PAYMENT": "#ffff1a",
  "READY_FOR_PAYMENT": "#ffff1a",
  "PAID BILL": " #8cff1a",
  "PAID_BILL": " #8cff1a",
  "Rejected": "#ff1a1a",
  "REJECT":"#ff1a1a"
};

const BillsList = () => {
  const [activeTab, setActiveTab] = useState("All Bills");
  const [bills, setBills] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(0);
  const [showUploadForm, setShowUploadForm] = useState(false);
  const [billDetails, setBillDetails] = useState(null);
  const [formView, setFormView] = useState("");

  const handleView = async (bill, viewType) => {
    try {
      const avenueInvoiceId = bill.avenue_created_invoice_id?.S;

      if (!avenueInvoiceId) {
        console.error("avenue_created_invoice_id is missing.");
        alert("Invoice ID is missing. Please provide a valid invoice.");
        return;
      }

      console.log(`Fetching details for avenue_created_invoice_id: ${avenueInvoiceId}`);

      const apiUrl = `https://z8knuzragg.execute-api.ap-southeast-1.amazonaws.com/dev/itemid?PK=Invoice&avenue_created_invoice_id=${encodeURIComponent(avenueInvoiceId)}`;

      const response = await fetch(apiUrl, {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });

      const result = await response.json();

      if (!response.ok) {
        throw new Error(result.error || "Failed to fetch bill details.");
      }

      console.log("Fetched bill details:", result);
      setBillDetails(result);
      setFormView(viewType);
    } catch (error) {
      console.error("Error in handleView:", error);
      alert(`Failed to load bill details: ${error.message}`);
    }
  };

  useEffect(() => {
    const fetchBills = async () => {
      try {
        const response = await fetch("https://z8knuzragg.execute-api.ap-southeast-1.amazonaws.com/dev/all");
        if (!response.ok) throw new Error("Failed to fetch bills from API.");
        const result = await response.json();
        console.log("Fetched bills:", result); // Debug log
        setBills(result || []);
      } catch (err) {
        console.error("Error fetching bills:", err);
      }
    };
    fetchBills();
  }, []);

  const tabStatusMap = {
    
    "Ready For Review": "READY_FOR_REVIEW",
    "Manage Inventory": "REVIEWED",
    "Make Payments": "READY_FOR_PAYMENT",
    "Processed Invoices": "PAID_BILL",
    "All Invoices": null,
  };

  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
    setCurrentPage(0);
  };

  const filteredBills = bills.filter((bill) => {
    const selectedStatus = tabStatusMap[activeTab];
    
    
    const isReadyForPaymentTab =
      activeTab === "Make Payments" &&
      (bill.bill_status?.S === "READY_FOR_PAYMENT" || bill.bill_status?.S === "REVIEWED");
  
    const matchesTab =
      selectedStatus === null ||
      bill.bill_status?.S === selectedStatus ||
      isReadyForPaymentTab;
  
    const matchesSearch =
      String(bill.json_invoice_number?.S || "").toLowerCase().includes(searchQuery.toLowerCase()) ||
      String(bill.supplier_name?.S || "").toLowerCase().includes(searchQuery.toLowerCase());
  
    return matchesTab && matchesSearch;
  });
  
  

  const handleChangePage = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setItemsPerPage(parseInt(event.target.value, 10));
    setCurrentPage(0);
  };

  const paginatedBills = filteredBills.slice(
    currentPage * itemsPerPage,
    currentPage * itemsPerPage + itemsPerPage
  );

  return (
    <Box sx={{ padding: 3, backgroundColor: "#F4F6F9", minHeight: "90vh" , marginLeft: "14px"}}>
      {/* Header */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#2C3E50" }}>
          Invoices
        </Typography>
        <Button
          variant="contained"
          startIcon={<UploadFileIcon />}
          onClick={() => setShowUploadForm(true)}
          sx={{ backgroundColor: "#2A3663", "&:hover": { backgroundColor: "#1E2A48" } }}
        >
          Upload Invoice
        </Button>
      </Box>

      {/* Tabs and Search */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 1 }}>
  <Tabs value={activeTab} onChange={handleTabChange}>
    {Object.keys(tabStatusMap).map((tab) => (
      <Tab key={tab} value={tab} label={tab} />
    ))}
  </Tabs>
  <TextField
    placeholder="Search Invoices"
    value={searchQuery}
    onChange={(e) => setSearchQuery(e.target.value)}
    sx={{ width: 300 }}
  />
</Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Invoice Number</TableCell>
              <TableCell>Supplier Name</TableCell>
              <TableCell>Uploaded Date</TableCell>
              <TableCell>Total Amount</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedBills.map((bill) => (
              <TableRow key={bill.avenue_created_invoice_id?.S}>
                <TableCell
                  sx={{ cursor: "pointer", color: "#1565C0", textDecoration: "underline" }}
                  onClick={() => {
                    const status = bill.bill_status?.S;
                    let viewType = "";
                    if (status === "READY_FOR_REVIEW") viewType = "ReadyForReview";
                    else if (status === "REVIEWED") viewType = "ReviewedForm";
                    else if (status === "READY_FOR_PAYMENT") viewType = "ReadyForPayment";
                    else if (status === "PAID_BILL") viewType = "PaidBillForm";
                    handleView(bill, viewType);
                  }}
                >
                  {bill.avenue_created_invoice_id?.S}
                </TableCell>
                <TableCell>{bill.supplier_name?.S}</TableCell>
                <TableCell>{bill.uploadedAtIST?.S}</TableCell>
                
                <TableCell>{bill.total_bill_amount?.S || "N/A"}</TableCell>
                <TableCell>
                  <Box
                    sx={{
                      display: "inline-block",
                      padding: "4px 12px",
                      borderRadius: "16px",
                      fontWeight: "bold",
                      backgroundColor: statusColors[bill.bill_status?.S] || "#F0F0F0",
                      color: "#2C3E50",
                    }}
                  >
                    {bill.bill_status?.S}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={filteredBills.length}
          page={currentPage}
          onPageChange={handleChangePage}
          rowsPerPage={itemsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      {/* Conditional Form Rendering */}
      {formView && billDetails && (
        <Box
          sx={{
            position: "fixed",
            top: "10%",
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "96%",
            height: "85vh",
            overflowY: "auto",
            backgroundColor: "#fff",
            boxShadow: 3,
            padding: 3,
            zIndex: 1300,
          }}
        >
          <IconButton
            onClick={() => {
              setBillDetails(null);
              setFormView("");
            }}
          >
            <ArrowBackIcon />
          </IconButton>
          {formView === "ReadyForReview" && <ReadyForReview invoiceData={billDetails} />}
          {formView === "ReadyForPayment" && <ReadyForPayment invoiceData={billDetails} />}
          {formView === "PaidBillForm" && <PaidBillForm invoiceData={billDetails} />}
          {formView === "ReviewedForm" && <ReviewedForm invoiceData={billDetails} />}
        </Box>
      )}

      {/* Upload Invoice Popup */}
      {showUploadForm && (
        <Box
          sx={{
            position: "fixed",
            top: "7%",
            left: "50%",
            transform: "translate(-50%, 0)",
            width: "100%",
            height:'100%',
            maxWidth: "1840px",
            maxHeight: "84vh", 
            overflowY: "auto",
            backgroundColor: "transparent",
            boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.1)",
            borderRadius: "8px",
            zIndex: 1300,
            padding: 2,
            marginTop: "26px",
          }}
        >
          <UploadInvoice onClose={() => setShowUploadForm(false)} />
        </Box>
      )}
    </Box>
  );
};

export default BillsList;
