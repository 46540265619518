import React, { useState } from "react";
import {
  Box,
  Typography,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Divider,
  Checkbox,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  DialogTitle,
} from "@mui/material";
import { Worker } from "@react-pdf-viewer/core";
import VisibilityIcon from "@mui/icons-material/Visibility";
import AddInventoryModal from "./AddInventoryModal";
import CreateInventoryModal from "./CreateInventoryModal";

const PaidBillForm = ({ invoiceData = [] }) => {
  // Extract the first item from the array
  const data = invoiceData[0] || {};

  const {
    supplier_name = "NA",
    supplier_address = "NA",
    supplier_gstin = "NA",
    invoice_date = "NA",
    invoice_number = "NA",
    json_invoice_number = "NA",
    products = [],
    total = 0,
    taxes_igst = 0,
    file_url,
  } = data;

  const subtotal = total - taxes_igst;

  // State to track selected checkboxes
  const [selectedRows, setSelectedRows] = useState([]);
  const [open, setOpen] = useState(false); // PDF dialog state

  const handleClickOpen = () => {
    setOpen(!open);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = (e, index) => {
    const isChecked = e.target.checked;
    const product = products[index];

    if (isChecked) {
      setSelectedRows((prevSelectedRows) => [...prevSelectedRows, product]);
    } else {
      setSelectedRows((prevSelectedRows) =>
        prevSelectedRows.filter((item) => item !== product)
      );
    }
  };

  // State to manage modal visibility
  const [isAddInventoryOpen, setIsAddInventoryOpen] = useState(false);
  const [isCreateInventoryOpen, setIsCreateInventoryOpen] = useState(false);

  const handleAddInventory = () => setIsAddInventoryOpen(true);
  const handleCloseAddInventory = () => setIsAddInventoryOpen(false);

  const handleCreateInventory = () => setIsCreateInventoryOpen(true);
  const handleCloseCreateInventory = () => setIsCreateInventoryOpen(false);

  return (
    <Box sx={{ padding: 4, backgroundColor: "#F9F9F9", minHeight: "100vh" }}>
      {/* Header */}
      <Box sx={{ display: "flex", justifyContent: "space-between", mb: 3 }}>
        <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2A3663" }}>
          Invoice Overview #{json_invoice_number}
        </Typography>
        <Box sx={{ display: "flex", gap: 2 }}>
          <IconButton onClick={handleClickOpen} color="primary">
            <VisibilityIcon />
          </IconButton>
          <Button variant="contained" sx={{ backgroundColor: "#1976d2" }} onClick={handleAddInventory}>
            Add Inventory
          </Button>
          <Button variant="contained" sx={{ backgroundColor: "#ff9800" }} onClick={handleCreateInventory}>
            Create Inventory
          </Button>
        </Box>
      </Box>

      {/* Invoice Overview */}
      <Box
        sx={{
          backgroundColor: "#FFFFFF",
          borderRadius: "8px",
          padding: "20px",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          marginBottom: "20px",
        }}
      >
        <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
          Vendor Name: {supplier_name}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          Vendor Address: {supplier_address}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          GSTIN: {supplier_gstin}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          Date: {invoice_date}
        </Typography>
        <Typography variant="subtitle1" sx={{ color: "#555" }}>
          Invoice Number: {invoice_number}
        </Typography>
      </Box>

      {/* Item Table */}
      <TableContainer component={Paper} sx={{ marginBottom: "20px", borderRadius: "8px" }}>
        <Table>
          <TableHead sx={{ backgroundColor: "#2A3663" }}>
            <TableRow>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Item Description</TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Code</TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Quantity</TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Unit Price</TableCell>
              <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Taxable Value</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={selectedRows.includes(product)}
                    onChange={(e) => handleClick(e, index)}
                  />
                  {product.description || "NA"}
                </TableCell>
                <TableCell>{product.code || "NA"}</TableCell>
                <TableCell>{product.quantity || 0}</TableCell>
                <TableCell>₹{parseFloat(product.unit_price || 0).toFixed(2)}</TableCell>
                <TableCell>₹{parseFloat(product.taxable_value || 0).toFixed(2)}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Totals */}
      <Box
        sx={{
          padding: 3,
          backgroundColor: "#FFFFFF",
          borderRadius: 4,
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
          width: "300px",
          marginLeft: "auto",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography sx={{ color: "#555" }}>Sub-Total</Typography>
          <Typography sx={{ fontWeight: "bold", color: "#2A3663" }}>₹{subtotal.toLocaleString()}</Typography>
        </Box>
        <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
          <Typography sx={{ color: "#555" }}>Taxes (IGST)</Typography>
          <Typography sx={{ fontWeight: "bold", color: "#2A3663" }}>₹{taxes_igst.toLocaleString()}</Typography>
        </Box>
        <Divider />
        <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2A3663" }}>
            Total Price
          </Typography>
          <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2A3663" }}>
            ₹{total.toLocaleString()}
          </Typography>
        </Box>
      </Box>

      {/* PDF Dialog */}
      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogTitle>Invoice PDF</DialogTitle>
        <DialogContent>
          <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", height: "500px" }}>
            <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`}>
              {file_url ? (
                <object data={file_url} type="application/pdf" width="100%" height="100%">
                  No PDF Available
                </object>
              ) : (
                <Typography>No PDF Available</Typography>
              )}
            </Worker>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Add Inventory Modal */}
      <Dialog open={isAddInventoryOpen} onClose={handleCloseAddInventory} maxWidth="md">
        <AddInventoryModal materialDetails={selectedRows} onClose={handleCloseAddInventory} />
      </Dialog>

      {/* Create Inventory Modal */}
      <Dialog open={isCreateInventoryOpen} onClose={handleCloseCreateInventory} maxWidth="md" fullWidth>
        <CreateInventoryModal materialDetails={selectedRows} onClose={handleCloseCreateInventory} />
      </Dialog>
    </Box>
  );
};

export default PaidBillForm;



// import React, { useState } from "react";
// import {
//   Box,
//   Typography,
//   Button,
//   Table,
//   TableBody,
//   TableCell,
//   TableContainer,
//   TableHead,
//   TableRow,
//   Paper,
//   Divider,
//   Checkbox,
//   Dialog,
//   DialogContent,
//   DialogActions,
//   IconButton,
//   DialogTitle,

//   Pagination,
// } from "@mui/material";
// import { Worker } from "@react-pdf-viewer/core";
// import VisibilityIcon from '@mui/icons-material/Visibility';

// import AddInventoryModal from "./AddInventoryModal";
// import CreateInventoryModal from "./CreateInventoryModal";

// const PaidBillForm = ({ invoiceData = {} }) => {
//   const {
//     supplier_name = "NA",
//     supplier_address = "NA",
//     supplier_gstin = "NA",
//     invoice_date = "NA",
//     invoice_number = "NA",
//     invoice_title = "NA",
//     products = [],
//     total = 0,
//     taxes_igst = 0,
//   } = invoiceData;

//   const subtotal = total - taxes_igst;


//   // Calculate pagination
//   const displayedProducts = products.slice(
//   );
//   const checkedValues = [];
//   // const handleClick = (e,i) => {
//   //   //Do something if checkbox is clicked
//   //   if (e.target.checked) {
//   //     console.log("value - ", e.target.value);

//   //    checkedValues.push(displayedProducts[i])
//   //   } 
//   //   console.log("checked val",checkedValues)
//   // };

//   // State to track selected checkboxes
//   const [selectedRows, setSelectedRows] = useState([]);
//   const [open, setOpen] = useState(false); // By default, PDF is shown (open is true)

//   const handleClickOpen = () => {
//     setOpen(!open); // Toggle open state to show or hide the PDF
//   };

//   const handleClose = () => {
//     setOpen(false); // Close the dialog
//   };
//   // Handle checkbox click
//   const handleClick = (e, index) => {
//     const isChecked = e.target.checked;
//     const product = displayedProducts[index];

//     if (isChecked) {
//       // Add the selected product to the selected rows
//       setSelectedRows((prevSelectedRows) => [...prevSelectedRows, product]);
//     } else {
//       // Remove the unselected product from selected rows
//       setSelectedRows((prevSelectedRows) =>
//         prevSelectedRows.filter((item) => item !== product)
//       );
//     }
//   };

//   // Handle "Get Selected" button click
//   const handleGetSelected = () => {
//     console.log("Selected Products:", selectedRows);
//   };


//   // State to manage modal visibility
//   const [isAddInventoryOpen, setIsAddInventoryOpen] = useState(false);
//   const [isCreateInventoryOpen, setIsCreateInventoryOpen] = useState(false);

//   // Handlers to open/close modals
//   const handleAddInventory = () => setIsAddInventoryOpen(true);
//   const handleCloseAddInventory = () => setIsAddInventoryOpen(false);

//   const handleCreateInventory = () => setIsCreateInventoryOpen(true);
//   const handleCloseCreateInventory = () => setIsCreateInventoryOpen(false);

//   return (
//     <Box
//     // sx={{
//     //   padding: "20px",
//     //   backgroundColor: "#f9f9f9",
//     //   minHeight: "100vw%",
//     //   fontFamily: "Arial, sans-serif",
//     //   marginRight:"30px",
//     //   marginLeft:"30px",
//     // }}
//     >
//       {/* Header */}
//       <Box
//         sx={{
//           display: "flex",
//           alignItems: "center",
//           justifyContent: "space-between",
//           marginBottom: "20px",
//         }}
//       >
//         <Typography
//           variant="h6"
//           sx={{
//             fontWeight: "bold",
//             color: "#2A3663",
//           }}
//         >
//           Invoice Overview #{invoiceData.json_invoice_number}
//         </Typography>
      
//         {/* Action Buttons */}
//         <Box
//           sx={{
//             display: "flex",
//             gap: 2,
//           }}
//         >
//             <div>
//           {/* Eye Icon Button */}
//           <IconButton onClick={handleClickOpen} color="primary" >
//             <VisibilityIcon />
//           </IconButton>
//         </div>
//           <Button
//             variant="contained"
//             sx={{
//               backgroundColor: "#1976d2",
//               "&:hover": {
//                 backgroundColor: "#1565c0",
//               },
//             }}
//             onClick={handleAddInventory}
//           >
//             Add Inventory
//           </Button>

//           <Button
//             variant="contained"
//             sx={{
//               backgroundColor: "#ff9800",
//               "&:hover": {
//                 backgroundColor: "#fb8c00",
//               },
//             }}
//             onClick={handleCreateInventory}
//           >
//             Create Inventory
//           </Button>
//         </Box>
//       </Box>

//       {/* Invoice Overview */}
//       <Box
//         sx={{
//           backgroundColor: "#fff",
//           borderRadius: "8px",
//           padding: "20px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//           marginBottom: "20px",
//         }}
//       >
//         <Box sx={{ display: "flex", justifyContent: "space-between", width: "85vw" }}>
//           <Box sx={{ width: "50%" }}>
//             <Typography
//               variant="subtitle1"
//               sx={{ fontWeight: "bold", marginBottom: "10px", color: "#2A3663" }}
//             >
//               Vendor Name: {supplier_name}
//             </Typography>
//             <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
//               Vendor Address: {supplier_address}
//             </Typography>
//             <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
//               GSTIN: {supplier_gstin}
//             </Typography>
//           </Box>
//           <Box textAlign="right" sx={{ marginLeft: "350px" }}>
//             <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
//               Date: {invoiceData.invoice_date}
//             </Typography>
//             <Typography variant="subtitle1" sx={{ fontWeight: "bold", color: "#555" }}>
//               Invoice Number: {invoice_number}
//             </Typography>

//           </Box>
//         </Box>
//       </Box>

//       {/* Item Table */}
//       <TableContainer
//         component={Paper}
//         sx={{
//           marginBottom: "20px",
//           borderRadius: "8px",
//           boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
//         }}
//       >
//         <Table>
//           <TableHead sx={{ backgroundColor: "#2A3663" }}>
//             <TableRow>
//               <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Item Description</TableCell>
//               <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Code</TableCell>
//               <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Quantity</TableCell>
//               <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Unit Price</TableCell>
//               <TableCell sx={{ color: "#fff", fontWeight: "bold" }}>Taxable Value</TableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {displayedProducts.map((product, index) => (
//               <TableRow key={index}>
//                 <TableCell>
//                   <Checkbox
//                     checked={selectedRows.includes(product)}
//                     onChange={(e) => handleClick(e, index)} />
//                   {product.description || "NA"}
//                 </TableCell>
//                 <TableCell>{product.code || "NA"}</TableCell>
//                 <TableCell>{product.quantity || 0}</TableCell>
//                 <TableCell>{parseFloat(product.unit_price || 0).toFixed(2)}</TableCell>
//                 <TableCell>{parseFloat(product.taxable_value || 0).toFixed(2)}</TableCell>
//               </TableRow>
//             ))}
//           </TableBody>
//         </Table>
//       </TableContainer>

//       {/* Pagination */}
//       <Box
//         sx={{
//           display: "flex",
//           justifyContent: "center",
//           marginTop: "20px",
//         }}
//       >

//       </Box>

//       {/* Totals */}
//       <Box
//         sx={{
//           display: "flex",
//           flexDirection: "column",
//           gap: 2,
//           padding: 3,
//           backgroundColor: "#ffffff",
//           borderRadius: 4,
//           boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
//           marginLeft: "65%",
//         }}
//       >
//         <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
//           <Typography variant="body1" sx={{ color: "#555" }}>
//             Sub-Total
//           </Typography>
//           <Typography variant="body1" sx={{ color: "#555" }}>
//             {subtotal || "5676"}
//           </Typography>
//         </Box>
//         <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "10px" }}>
//           <Typography variant="body1" sx={{ color: "#555" }}>
//             Taxes (IGST)
//           </Typography>
//           <Typography variant="body1" sx={{ color: "#555" }}>
//             {taxes_igst}
//           </Typography>
//         </Box>
//         <Divider />
//         <Box sx={{ display: "flex", justifyContent: "space-between", marginTop: "10px" }}>
//           <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2A3663" }}>
//             Total Price
//           </Typography>
//           <Typography variant="h6" sx={{ fontWeight: "bold", color: "#2A3663" }}>
//             {total}
//           </Typography>
//         </Box>
//       </Box>
//       <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
//         <DialogTitle>Invoice PDF</DialogTitle>
//         <DialogContent>
//           <Box
//             sx={{
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//               width: "100%",
//               height: "500px", // Or any height suitable for your design
//             }}
//           >
//             <Worker workerUrl={`https://unpkg.com/pdfjs-dist@3.11.174/build/pdf.worker.min.js`} >
//               {invoiceData?.file_url ? (
//                 <object
//                   data={invoiceData.file_url}
//                   type="application/pdf"
//                   width="100%"
//                   height="500px"
//                 >
//                   No PDF Available
//                 </object>
//               ) : (
//                 <Typography>No PDF Available</Typography>
//               )}
//             </Worker>
//           </Box>
//         </DialogContent>
//         <DialogActions>
//           <Button onClick={handleClose} color="primary">
//             Close
//           </Button>
//         </DialogActions>
//       </Dialog>

//       {/* Add Inventory Modal */}
//       <Dialog open={isAddInventoryOpen} onClose={handleCloseAddInventory} maxWidth="md"  >
//         <AddInventoryModal
//           materialDetails={selectedRows}
//           onClose={handleCloseAddInventory}
//         />
//       </Dialog>

//       {/* Create Inventory Modal */}
//       <Dialog open={isCreateInventoryOpen} onClose={handleCloseCreateInventory} maxWidth="md" fullWidth>
//         <CreateInventoryModal
//           materialDetails={selectedRows}
//           onClose={handleCloseCreateInventory}
//         />
//       </Dialog>
//     </Box>
//   );
// };

// export default PaidBillForm;