import React, { useEffect, useState } from "react";
import { Box, TextField, Typography, Autocomplete, Avatar, Grid, CircularProgress } from "@mui/material";

const VendorDropdown = ({ onSelect, onCreateVendor, value }) => {
  const [vendors, setVendors] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchVendors = async () => {
      setLoading(true);
      try {
        const response = await fetch("https://2tihrfzt93.execute-api.ap-southeast-1.amazonaws.com/Dev/vendor");
        const data = await response.json();
        console.log(data)
        if (response.ok && data.body) {
          const parsedBody = JSON.parse(data.body);
          const apiVendors = parsedBody.vendors.map((vendor, index) => ({
            id: vendor.vendor_id || `vendor_${Math.random()}`,
            name: vendor.display_name || "Unnamed Vendor",
            contactName: vendor.contact_name || "N/A",
            bankName: vendor.vendor_bank_name || "N/A",
            gstNumber: vendor.gst_identification_number || "N/A",
            avatar: "https://via.placeholder.com/40",  
          }));

          // Deduplicate vendors based on the name
          const uniqueVendors = Array.from(
            new Map(apiVendors.map((vendor) => [vendor.name, vendor])).values()
          );

          // Add "Create New Vendor" option
          setVendors([...uniqueVendors, { id: "create_new", name: "Create New Vendor" }]);
        } else {
          console.error("Failed to fetch vendors:", data.message);
        }
      } catch (error) {
        console.error("Error fetching vendors:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchVendors();
  }, []);

  return (
    <Autocomplete
      options={vendors}
      value={value}
      getOptionLabel={(option) => option.name || ""}
      onChange={(event, newValue) => {
        if (newValue && newValue.id === "create_new") {
          onCreateVendor();
        } else {
          onSelect(newValue);
        }
      }}
      loading={loading}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Search Vendors"
          fullWidth
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? <CircularProgress size={20} /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      renderOption={(props, option) => (
        <Box
          component="li"
          {...props}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            border: "1px solid #E0E0E0",
            borderRadius: "8px",
            padding: 2,
            marginBottom: 1,
            maxWidth: "100%",
          }}
        >
          {option.id === "create_new" ? (
            <Typography sx={{ color: "#2A3663", fontWeight: "bold", display: "flex", alignItems: "center" }}>
              + Create New Vendor
            </Typography>
          ) : (
            <Grid container spacing={2} alignItems="center">
              <Grid item>
                <Avatar src={option.avatar} alt={option.name} />
              </Grid>
              <Grid item xs>
                <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
                  {option.name}
                </Typography>
                {/* <Typography variant="body2" color="textSecondary">
                  {option.contactName} • {option.phone}
                </Typography> */}
              </Grid>
            </Grid>
          )}
        </Box>
      )}
      sx={{
        "& .MuiAutocomplete-popupIndicator": {
          color: "#2A3663",
        },
        "& .MuiOutlinedInput-root": {
          borderRadius: "8px",
        },
      }}
    />
  );
};

export default VendorDropdown;
