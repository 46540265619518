import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";

const InventoryList = () => {
  const [inventory, setInventory] = useState([]); // Inventory items fetched from API
  const [searchQuery, setSearchQuery] = useState(""); // Search query
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [currentPage, setCurrentPage] = useState(1); // Current page
  const [loading, setLoading] = useState(true); // Loading state
  const [error, setError] = useState(null); // Error state

  // Fetch inventory from the API
  useEffect(() => {
    const fetchInventory = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          "https://wiykuljd3h.execute-api.ap-southeast-1.amazonaws.com/dev/all" // Replace with your actual API endpoint
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch inventory. Status: ${response.status}`);
        }

        const result = await response.json();
        console.log("Full API Response:", result);

        // Set the inventory directly since the response is already an array
        if (Array.isArray(result)) {
          setInventory(result);
          setLoading(false);
        } else {
          throw new Error("Unexpected API response structure.");
        }
      } catch (err) {
        console.error("Error fetching inventory:", err);
        setError(err.message || "Unable to fetch inventory. Please try again later.");
        setLoading(false);
      }
    };

    fetchInventory();
  }, []);

  // Filter inventory based on search query
  const filteredInventory = inventory.filter((item) =>
    item.item_name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const totalPages = Math.ceil(filteredInventory.length / itemsPerPage);
  const paginatedInventory = filteredInventory.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const handlePageChange = (newPage) => setCurrentPage(newPage);

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(event.target.value);
    setCurrentPage(1); // Reset to page 1 when items per page changes
  };

  // Render loading and error states
  if (loading) {
    return (
      <Box sx={{ textAlign: "center", marginTop: 4 }}>
        <Typography>Loading inventory...</Typography>
      </Box>
    );
  }

  

  return (
    <Box
      sx={{
        padding: 2,
        minHeight: "84vh",
        backgroundColor: "#ffffff",
        borderRadius: "8px",
        width: "100%",
        maxWidth: "1840px",
      }}
    >
      {/* Header */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 3 }}>
        <Typography variant="h4" sx={{ fontWeight: "bold", color: "#2C3E50" }}>
          Inventory List
        </Typography>
        <Button
          variant="contained"
          startIcon={<AddBoxIcon />}
          sx={{
            backgroundColor: "#2A3663",
            color: "#FFFFFF",
            borderRadius: "4px",
            "&:hover": { backgroundColor: "#3B4A7A" },
          }}
        >
          Add Inventory
        </Button>
      </Box>

      {/* Search Bar */}
      <Box sx={{ mb: 3 }}>
        <TextField
          variant="outlined"
          size="small"
          placeholder="Search Inventory"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          sx={{ width: "400px", backgroundColor: "#FFFFFF", borderRadius: "4px" }}
        />
      </Box>

      {/* Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ backgroundColor: "#6A7392" }}>
            <TableRow>
              {/* <TableCell>Item ID</TableCell> */}
              <TableCell>Item Name</TableCell>
              <TableCell>Warehouse</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Stock Count</TableCell>
              <TableCell>Date</TableCell>
              {/* <TableCell>Rate</TableCell> */}
              <TableCell>QR Code</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {paginatedInventory.map((item, index) => (
              <TableRow key={index}>
                {/* <TableCell>{item.bill_number || "N/A"}</TableCell> */}
                <TableCell>{item.item_name || "Unnamed Item"}</TableCell>
                <TableCell>{item.warehouse || "Unknown"}</TableCell>
                <TableCell>{item.location || "Not Specified"}</TableCell>
                <TableCell>{item.count || 0}</TableCell>
                <TableCell>{item.date || "N/A"}</TableCell>
                {/* <TableCell>{item.rate || 0}</TableCell> */}
                <TableCell>
                  <a href={item.qr_url || "#"} target="_blank" rel="noopener noreferrer">
                    View QR Code
                  </a>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Pagination */}
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mt: 3 }}>
        <Typography>
          Showing {(currentPage - 1) * itemsPerPage + 1} -{" "}
          {Math.min(currentPage * itemsPerPage, filteredInventory.length)} of{" "}
          {filteredInventory.length}
        </Typography>
        <Box>
          <Select
            value={itemsPerPage}
            onChange={handleItemsPerPageChange}
            size="small"
            sx={{ width: "150px" }}
          >
            <MenuItem value={10}>10 per page</MenuItem>
            <MenuItem value={16}>16 per page</MenuItem>
            <MenuItem value={24}>24 per page</MenuItem>
          </Select>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Button
            onClick={() => handlePageChange(Math.max(1, currentPage - 1))}
            disabled={currentPage === 1}
            variant="outlined"
          >
            Prev
          </Button>
          {[...Array(totalPages)].map((_, index) => (
            <Button
              key={index + 1}
              onClick={() => handlePageChange(index + 1)}
              variant={index + 1 === currentPage ? "contained" : "outlined"}
            >
              {index + 1}
            </Button>
          ))}
          <Button
            onClick={() => handlePageChange(Math.min(totalPages, currentPage + 1))}
            disabled={currentPage === totalPages}
            variant="outlined"
          >
            Next
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default InventoryList;
